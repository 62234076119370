<template>
  <div id="message">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />星级申报
        </van-col>
        <van-col span="14" style="text-align: right" @click="onApplyClick()">
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col>
      </van-row>
      <van-row class="tabs">
        <van-col
          span="8"
          v-for="(item, index) in tabs"
          :key="index"
          :class="[item.type == query.status ? 'tab-item-active' : 'tab-item']"
          @click="onTabClick(item)"
          >{{ item.text }}</van-col
        >
      </van-row>
    </div>

    <div class="message-container">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_notifications"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            @click="onIssueItemClick(item)"
            :class="[item.is_read ? 'issue-item-read' : 'issue-item']"
          >
            <van-row>
              <van-col span="14">
                <div
                  :class="[
                    item.is_read
                      ? 'issue-item-title-read van-ellipsis'
                      : 'issue-item-title van-ellipsis',
                  ]"
                >
                  {{
                    item.travel_company_name ? item.travel_company_name : "暂无"
                  }}
                </div></van-col
              >
              <van-col span="10">
                <div
                  :class="[
                    item.is_read
                      ? 'issue-item-date text-read'
                      : 'issue-item-date',
                  ]"
                >
                  <van-rate
                    v-model="item.star"
                    :size="12"
                    color="#FFAA00"
                    void-icon="star"
                    void-color="#eee"
                    readonly
                  />
                </div>
              </van-col>
            </van-row>
            <!-- <div
              v-if="!item.is_read"
              :class="[item.is_read ? 'dot background-read' : 'dot']"
            ></div> -->
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <!-- <Tarbar :active="4"></Tarbar> -->
  </div>
</template>

<script>
import api from "@/services/apis.js";
import Tarbar from "../components/Tarbar.vue";

export default {
  name: "start-manage",
  components: { Tarbar },
  data() {
    return {
      showPopover: false,
      list: [],
      active: 0,
      loading: true,
      finished: false,
      refreshing: false,
      page_status: "",
      query: {
        page: 1,
        status: "approving",
      },

      tabs: [
        {
          id: 1,
          text: "审核中",
          type: "approving",
        },
        {
          id: 2,
          text: "审核通过",
          type: "approve_pass",
        },
        {
          id: 3,
          text: "审核不通过",
          type: "approve_deny",
        },
      ],

      // 奖补相关
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null,
      },
      jiangbuList: [],
    };
  },
  methods: {
    // 返回上一层
    onClickLeft() {
      this.$router.push("/");
    },
    // 切换类别
    onTabClick(item) {
      this.query.status = item.type;
      this.onRefresh();
    },
    // 刷新数据
    onRefresh() {
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_notifications();
    },
    // 获取列表数据
    async get_notifications() {
      const res = await this.$apis.get_star_list(this.query);
      if (res.data) {
        this.loading = false;
        this.list = [...this.list, ...res.data];
        console.log(this.list);
        this.fillPagingtwo(res);
        this.query.page += 1;
      }
    },
    // 跳转到申请星级页面
    onApplyClick() {
      this.$router.push("/startManage/applyStar");
    },
    // 点击某条数据详情
    onIssueItemClick(item) {
      this.$router.push({
        path: "/startManage/item",
        query: {
          id: item.id,
        },
      });
    },

    // 获取奖补数据
    async refreshGift() {
      this.finished = false;
      this.loading = true;
      const _self = this;
      let params = {
        status: ["approving", "approve_pass", "approve_deny"],
      };
      let res = await api.get_jiangbu_list(params);
      console.log("zzz", res.data);

      if (res.status === 200) {
        this.jiangbuList = res.data;
        _self.loading = false;
        _self.fillPagingtwo(res);
      }
      console.log("奖补列表", this.jiangbuList);
    },
    loadJianbuPage() {
      this.finished = false;
      this.loading = true;
      const _self = this;
      if (this.paging.next_page != null) {
        let params = {
          //   'status':["approving","approve_pass","approve_deny"],
          page: this.paging.next_page,
        };
        api.get_jiangbu_list(params).then((res) => {
          if (res.status === 200) {
            res.data.forEach((ele) => {
              this.jiangbuList.push(ele);
            });
            _self.loading = false;
            _self.fillPagingtwo(res);
          }
        });
      }
    },

    fillPagingtwo(res) {
      this.paging.total_count = res.page.total_count;
      this.paging.total_pages = res.page.total_pages;
      this.paging.next_page = res.page.next_page;
      this.paging.current_page = res.page.current_page;
      if (res.page.next_page) {
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
    },
    onMessageClicktwo(item) {
      this.$router.push({
        path: `/message/jiangbuinfo`,
        query: { id: item.jiang_bu.id },
      });
    },
    // 跳转可以申请的奖补列表
    toJiangbuList() {
      this.$router.push("/message/jiangbuList");
    },
    // ---end--
  },
  created() {
    if (this.$route.query.notification_type == "6") {
      this.query.notification_type = this.$route.query.notification_type;
    }
    if (this.query.notification_type == "4") {
      this.onRefresh1();
    } else if (this.query.notification_type == "6") {
      this.refreshGift();
    } else {
      this.onRefresh();
    }
  },
};
</script>

<style scoped>
#message {
  height: 100vh;
  padding-top: 20vh;
}

.action_btn {
  position: fixed;
  bottom: 70px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 130px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}

.header {
  height: 20vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.message-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.message-item {
  background: #ffffff;
  height: 1.5rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.3rem;
  position: relative;
  margin-bottom: 10px;
}
.message-item-title {
  position: absolute;
  top: 0.3rem;
  left: 0.4rem;
  display: inline;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.message-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.message-item-date {
  font-size: 12px;
  color: #999999;
  position: absolute;
  top: 0.3rem;
  right: 0.4rem;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.report-item-container {
  background: #fff;
  padding: 0.2rem 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
/* .tuan-info {
  margin-top: 1.6rem;
} */
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: rgba(173, 173, 173, 0.2);
  color: #c1c1c1;
  padding: 2px 0.2rem;
}

.issue-item {
  background: #ffffff;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.issue-item-date {
  font-size: 12px;
  color: #999999;
  text-align: right;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
.message-content {
  border-top: 1px dashed #ebedf0;
  margin-top: 20px;
}
.message-content-title {
  font-size: 14px;
  color: #333333;
  height: 0.8rem;
  line-height: 0.8rem;
  font-weight: bold;
}
.message-content-name {
  font-size: 14px;
  color: #333333;
  height: 0.5rem;
  line-height: 0.5rem;
  font-weight: bold;
}
.message-content-date {
  font-size: 12px;
  color: #666666;
  text-align: right;
}
.message-content-detail {
  font-size: 12px;
  color: #666666;
  text-align: justify;
}
.message-time {
  position: absolute;
  top: -0.4rem;
  left: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.edit-icon {
  font-size: 0.6rem;
}
.issue-item-read {
  background: #f1f1f1;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-title-read {
  color: #8c92a4;
  font-size: 12px;
  font-weight: bold;
}
.text-read {
  color: #8c92a4 !important;
}
.background-read {
  background-color: #8c92a4 !important;
}
.report-item {
  margin-bottom: 12px;
  /* background: #6c94f6 */
}
.van-radio__label {
  font-size: 0.2rem !important;
  color: #fff !important;
}
</style>
